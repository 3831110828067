import { useQueries } from 'react-query'
import apiService from '../../modules/Package/CreatePackageConsignment/apiService'
import { map } from 'lodash'

export const useGetTasksConsignmentQueries  = (taskIds , options={}) =>{
    const fetcher = async (taskId) => {
        const response = await apiService.getTask(taskId)
        return response.data
    }

    return useQueries([
        ...map(taskIds, (taskId) => ({
            queryKey: ['tasks', taskId],
            queryFn: () => fetcher(taskId),
            retry: false,
            ...options,
        }))
    ])
}

