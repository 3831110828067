import React, { Component } from 'react'
import { Button, Col, Form, Layout, notification, Row } from 'antd'
import clsx from 'clsx'
import { debounce, find, get, isEqual, isFunction, isNil, map, omit } from 'lodash'
import PropTypes from 'prop-types'

import {
    DEFAULT_STATE_FORM_CUSTOMER,
    DELIVERY_NOTE_ACTION,
    DELIVERY_NOTE_SETTING_KEY,
    MAXIMUM_COUNT_WAIT_INTERVAL,
} from '../../constants'

import CanDeliveries from './CanDeliveries/CanDeliveriesContainer'
import ReadyForDeliveries from './ReadyForDeliveries/ReadyForDeliveriesContainer'
import CancelDeliveryNote from './../../CancelDeliveryNote/CancelDeliveryNoteContainer'
import DeliveryNoteExport from './../../DeliveryNoteExport/DeliveryNoteExportContainer'
import CustomerFormInput from './../../FormCustomer/components/CustomerFormInput'
import CustomerInfo from './../../FormCustomer/components/CustomerInfo'
import FormCustomerLoading from './../../FormCustomer/components/FormCustomerLoading'
import PlaceDeliveryNote from './../../PlaceDeliveryNote/PlaceDeliveryNoteContainer'
import authService from '../../../Auth/authService'
import permissionsContants from '../../../Auth/permissions'
import apiService from '../../Create/apiService'
import isEmpty from 'lodash/isEmpty'
import { getVar } from '../../../../system/support/helpers'

const { Content } = Layout;

class DetailInfoForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_STATE_FORM_CUSTOMER,
            submit: false,
            id_last_mile_carrier: undefined,
            loadingPreSubmit: '',
            intervalOld: 0,
            newReadyForDeliveries: [],
        };
    }

    componentDidUpdate(prevProps) {
        if(get(prevProps, "deliveryNote") !== this.props.deliveryNote ) {
            const lastBarcode = find(get(this.props.deliveryNote, 'barcodes', []), ['type', 'PACKAGE']);
                this.setState( {
                    customer_phone: getVar(lastBarcode, 'delivery_request.receiver_phone') || getVar(lastBarcode, 'package.customer_phone_destination'),
                    customer_address: getVar(lastBarcode, 'delivery_request.receiver_address') || getVar(lastBarcode, 'package.customer_address_destination')
                })
        }

    }



    static getDerivedStateFromProps(props, state) {
        const { customerDetailInfo, deliveryNote, identity, readyForDeliveries} = props;

        let nextState = {
            ...state
        };
        const customerId = get(customerDetailInfo, 'customer.id', 0);
        const deliveryNoteId = get(deliveryNote, 'delivery_note.id');

        if (state.deliveryNoteId !== deliveryNoteId) {
            nextState = !deliveryNoteId ? {...DEFAULT_STATE_FORM_CUSTOMER} : {
                note: get(deliveryNote, 'delivery_note.note', ''),
                amount_collect: get(deliveryNote, 'delivery_note.amount_collect', '')
            };
            nextState.id_warehouse = get(deliveryNote, 'delivery_note.id_warehouse', 0);
            nextState.cod = get(deliveryNote, 'delivery_note.cod');
            nextState.domestic_shipping_fee = get(deliveryNote, 'delivery_note.domestic_shipping_fee');
            nextState.id_last_mile_carrier = get(deliveryNote, 'delivery_note.id_last_mile_carrier');
            nextState.id_last_mile_carrier = get(deliveryNote, 'delivery_note.id_last_mile_carrier');
            nextState.deliveryNoteId = deliveryNoteId;
        }

        if (state.identity !== identity) {
            nextState.identity = identity;
        }

        if (state.id_customer !== customerId) {
            nextState.id_customer = customerId;
        }

        if (!isEqual(state.deliveryNote, deliveryNote)) {
            nextState.deliveryNote = deliveryNote;
            nextState.length = get(deliveryNote, 'delivery_note.length');
            nextState.width = get(deliveryNote, 'delivery_note.width');
            nextState.height = get(deliveryNote, 'delivery_note.height');
        }

        if (state.deliveryNoteId !== deliveryNoteId || state.id_customer !== customerId) {
            nextState.customer_receiver = !deliveryNoteId ?
                get(customerDetailInfo, 'customer.full_name', '') : get(deliveryNote, 'delivery_note.customer_receiver', '');

            if (deliveryNoteId) {
                nextState.negative_balance = get(deliveryNote, 'delivery_note.negative_balance');
            } else if (get(customerDetailInfo, 'customer_finance.amount_remaining') < 0) {
                nextState.negative_balance = Math.abs(customerDetailInfo.customer_finance.amount_remaining);
                nextState.cod = nextState.negative_balance;
            }
        }

        const newReadyForDeliveries = [];
        readyForDeliveries.map(item => {
            const packageCodeReadyDelivery = get(item, "package.code");
            const packageInfo = find(state.newReadyForDeliveries, ['package.code', packageCodeReadyDelivery])
            if (!isEmpty(packageInfo)){
                newReadyForDeliveries.push({...item, ...packageInfo})
            } else {
                newReadyForDeliveries.push(item)
            }
            
        })

        nextState.packages = props.packages;
        nextState.bags = props.bags;
        nextState.newReadyForDeliveries = newReadyForDeliveries

        return nextState;
    }

    onUpdate = params => {
        this.setState(prevState => {
            const newState = {
                ...prevState,
                ...params,
                submit: false
            };

            this.doEmitOnUpdate(omit(newState, 'deliveryNote'));

            return newState;
        });
    };

    emitOnUpdate = data => {
        const { onUpdate } = this.props;

        if (isFunction(onUpdate)) {
            onUpdate(data);
        }
    };

    doEmitOnUpdate = debounce(this.emitOnUpdate, 250);

    handleCreateTaskCheckPayment = (data, auto) => {
        const {id_customer, packages, bags} = this.state;
        const { t, onSubmit, readyForDeliveries } = this.props;
        this.setState({loadingPreSubmit: auto ? 'create-with-tracking-no' : 'create_delivery_note'})
        apiService.createTaskCheckPaymentStatus({id_customer, id_packages: packages, id_bags: bags})
        .then(res => {
            const taskId = get(res, 'data.task._id', '')
            const ignore_payment_confirmation = get(res, 'data.ignore_payment_confirmation', false)
            if (ignore_payment_confirmation) {
                this.setState({loadingPreSubmit: ''})
                    const newReadyForDeliveries = [];
                    readyForDeliveries.map(item => {
                        let paymentStatus = 1;
                        newReadyForDeliveries.push({...item, payment_status: paymentStatus})
                    })
                    this.setState({newReadyForDeliveries})
                onSubmit({...data, task_id: taskId}, auto)
            } else {
                this.handleInterval(taskId, data, auto)
            }
        }).catch(() => {
            notification.error({message: t("message.server_error")})
            this.setState({loadingPreSubmit: ''})
        })
    }

    handleInterval = (taskId, data, auto) => {
        const { t, onSubmit, readyForDeliveries } = this.props;
        let stt = 0
        const {intervalOld} = this.state;
        const interval = setInterval(async () => {
            clearInterval(intervalOld)
            stt++
            try {
                const res = await apiService.handleProcessTaskId(taskId)
                const status = get(res, "data.task.status");
                const readyForExport = get(res, "data.task.ready_for_export", false)
                const errors = get(res, "data.task.errors", [])
                const packages = get(res, "data.task.packages", [])
                if (status === "FINISH" && errors.length === 0 && readyForExport) {
                    clearInterval(interval)
                    this.setState({loadingPreSubmit: ''})
                    const newReadyForDeliveries = [];
                    readyForDeliveries.map(item => {
                        let paymentStatus = 1;
                        newReadyForDeliveries.push({...item, payment_status: paymentStatus})
                    })
                    this.setState({newReadyForDeliveries})
                    onSubmit({...data, task_id: taskId}, auto)
                    
                } else {
                    if (errors.length > 0) {
                        clearInterval(interval)
                        this.setState({loadingPreSubmit: ''})
                        const newReadyForDeliveries = [];
                        readyForDeliveries.map(item => {
                            let paymentStatus = 1;
                            const packageCodeReadyDelivery = get(item, "package.code");
                            const packageInfo = find(packages, ['code', packageCodeReadyDelivery])
                            if (!isEmpty(packageInfo)){
                                paymentStatus =  get(packageInfo, "payment_status", "") === "success" ? 1 : 0;
                            }
                            newReadyForDeliveries.push({...item, payment_status: paymentStatus})
                        })
                        this.setState({newReadyForDeliveries})
                        const messages = errors.map(error => t(`delivery_note:message.${error}`))
                        notification.error({
                            message: messages.join(", "), 
                        })
                    }
                }
                
            } catch (error) {
                clearInterval(interval)
                this.setState({loadingPreSubmit: ''})
                notification.error({message: t("message.server_error")})
            }
            if (stt >= MAXIMUM_COUNT_WAIT_INTERVAL) {
                clearInterval(interval)
                this.setState({loadingPreSubmit: ''})
                notification.error({
                    message: t('delivery_note:message.over_time_allow'),
                })
            }
        }, 8000)
        this.setState({
            intervalOld: interval
        })
    }

    onSubmit = (auto = false) => {
        const { deliveryNote, warehouseId, onSubmit, t, customerDetailInfo, agencySetting, globalSetting} = this.props;
        const { intervalOld } = this.state; 
        clearInterval(intervalOld)
        this.setState({ intervalOld: 0 })
        const { agency } = customerDetailInfo;
        const id_agency = get(agency, "id")
        
        const data = omit({
            ...this.state,
            id_warehouse: warehouseId
        }, ['local_delivery_charge', 'local_delivery_charge_note', 'deliveryNote', "newReadyForDeliveries"]);

        data.length = isNil(data.length) ? 0 : data.length;
        data.width = isNil(data.width) ? 0 : data.width;
        data.height = isNil(data.height) ? 0 : data.height;
        data.negative_balance = isNil(data.negative_balance) ? 0 : data.negative_balance;
        data.domestic_shipping_fee = isNil(data.domestic_shipping_fee) ? 0 : data.domestic_shipping_fee;
        data.cod = isNil(data.cod) ? 0 : data.cod;
        data.id = get(deliveryNote, 'delivery_note.id', 0);
        data.id_last_mile_carrier = isNil(data.id_last_mile_carrier) ? 0 : data.id_last_mile_carrier;
        if (auto && !data.id_last_mile_carrier) {
            notification.error({message: t('validation:required', {attribute: t('shipping_partner')})})
        } else {
            const ignoreCallPayment = get(find(agencySetting, {code: DELIVERY_NOTE_SETTING_KEY.DELIVERY_NOTE_IGNORE_CALL_PAYMENT, id_agency}), "value", get(find(globalSetting, ["code", DELIVERY_NOTE_SETTING_KEY.DELIVERY_NOTE_IGNORE_CALL_PAYMENT]), "value", false));
            if (ignoreCallPayment) {
                onSubmit(data, auto);
            } else {
                this.handleCreateTaskCheckPayment(data, auto)
            }
        }
    };

    render() {
        const { customer_receiver, newReadyForDeliveries,customer_address,customer_phone } = this.state;
        const {
            actionsClassName,
            bags,
            canDeliveries,
            canUpdateReadyForDeliveries,
            creating,
            customerDetailInfo,
            deliveryNote,
            errors,
            identity,
            loading,
            loadingCanDeliveries,
            loadingCustomerDetailInfo,
            packages,
            readyForDeliveries,
            showCancel,
            showExport,
            showPlace,
            showPrint,
            showUpdate,
            t,
            updating,
            onOpenPackageListByBagPopup,
            onPrint,
            onReloadCanDeliveries,
            onRemoveReadyForDelivery,
            onSuccessCancelDeliveryNote,
            onSuccessExportDeliveryNote,
            onSuccessPlaceDeliveryNote,
            creatingWithTrackingNo
        } = this.props;
        const { customer, permissions } = deliveryNote;
        const { customer_finance, agency } = customerDetailInfo;
        const canCancel = !!(get(permissions, 'can_cancel') && showCancel);
        const canExport = !!(get(permissions, 'can_export') && showExport);
        const canPrint = !!get(permissions, 'can_print') && get(deliveryNote, 'delivery_note.status') === 'EXPORTED' && showPrint;
        const canUpdate = !!(get(permissions, 'can_update') && showUpdate);
        const canPlace = !!(get(permissions, 'can_place') && showPlace);
        const isLastmileShipmentAutoCreate = authService.can(permissionsContants.LASTMILE_SHIPMENT_AUTOMATION_CREATE)
        const customerInfo = getVar(deliveryNote, 'customer_info', {});
        const mapPhone =map(getVar(customerInfo, "phone"), i => {
            return {
                value:i,
                default: false
            }
        })
        const mapAddress = map(getVar(customerInfo, "address"), i => {
            return {
                value:i,
                default: false
            }
        })

        const remapCustomerInfo = {
            ...customerInfo,
            phone: customer_phone ? [...mapPhone, {
                value:customer_phone,
                default: true
            }] :mapPhone,
            address: customer_address ? [...mapAddress, { value:customer_address, default: true }] : mapAddress
        }

        return (
            <Content className="detail-info-form gflex gflex-direction-column">
                <Form className="a-block amb-16">
                    <div className="block--primary">
                        <div className="ap-16">
                            <Row gutter={{lg: 24}}>
                                <div className="a-block-info pb-0">
                                    {loadingCustomerDetailInfo ? (
                                        <FormCustomerLoading />
                                    ) : (
                                        <CustomerInfo customer={customer} customer_finance={customer_finance} />
                                    )}
                                    <CustomerFormInput
                                        bags={bags}
                                        customer={customer}
                                        customerFinance={customer_finance}
                                        customerInfo={remapCustomerInfo}
                                        deliveryNote={get(deliveryNote, 'delivery_note')}
                                        errors={errors}
                                        identity={identity}
                                        id_customer={get(customerDetailInfo, 'customer.id')}
                                        loading={loading}
                                        packages={packages}
                                        onUpdate={this.onUpdate}
                                        resetError={this.props.resetError}
                                        readyForDeliveries={newReadyForDeliveries}
                                        agency={agency}
                                    />
                                </div>
                            </Row>
                        </div>
                    </div>
                </Form>
                <Row className="aheight-100 aflex-1 apx-16" gutter={16} type="flex">
                    <Col className="aheight-100" lg={{span: 12}} span={24}>
                        <CanDeliveries
                            canDeliveries={canDeliveries}
                            loading={loadingCanDeliveries}
                            onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                            onReload={onReloadCanDeliveries}
                            agency={agency}
                            action={DELIVERY_NOTE_ACTION.EDIT}
                            readyForDeliveries={newReadyForDeliveries}
                        />
                    </Col>
                    <Col className="aheight-100" lg={{span: 12}} span={24}>
                        <ReadyForDeliveries
                            canUpdate={canUpdateReadyForDeliveries && !(updating || !!this.state.loadingPreSubmit || creating || creatingWithTrackingNo)}
                            readyForDeliveries={newReadyForDeliveries}
                            onOpenPackageListByBagPopup={onOpenPackageListByBagPopup}
                            onRemove={onRemoveReadyForDelivery}
                            agency={agency}
                        />
                    </Col>
                </Row>

                {(!get(deliveryNote, 'delivery_note.id') || canExport || canPrint || canUpdate || canPlace || isLastmileShipmentAutoCreate) && (
                    <section className={clsx('actions ap-16 a-text--right', actionsClassName)}>
                        {
                            isLastmileShipmentAutoCreate && !get(deliveryNote, 'delivery_note.id') &&
                            <Button 
                                className='mr-2 a-btn a-btn--primary _btn-create-with-tracking-no'
                                disabled={!customer_receiver}
                                loading={creatingWithTrackingNo || this.state.loadingPreSubmit === 'create-with-tracking-no' } 
                                type="primary"
                                onClick={() => this.onSubmit(true)} 
                            >
                                {t('delivery_note:btn.create_lastmile_shipment_and_print')}
                            </Button>
                        }

                        {!get(deliveryNote, 'delivery_note.id') && (
                            <Button
                                className="a-btn a-btn--primary _btn-create"
                                disabled={!customer_receiver}
                                loading={creating || this.state.loadingPreSubmit === 'create_delivery_note'}
                                type="primary"
                                onClick={() => this.onSubmit(false)}
                            >
                                {t('create_delivery_note')}
                            </Button>
                        )}

                        {canCancel && (
                            <CancelDeliveryNote
                                deliveryNoteData={get(deliveryNote, 'delivery_note')}
                                onSuccessCancelDeliveryNote={onSuccessCancelDeliveryNote}
                            />
                        )}

                        {canPlace && (
                            <PlaceDeliveryNote
                                className="a-btn a-btn--primary"
                                isNewTab
                                deliveryNote={deliveryNote}
                                onSuccessPlaceDeliveryNote={onSuccessPlaceDeliveryNote}
                            />
                        )}

                        {canExport && (
                            <DeliveryNoteExport
                                bagTotal={get(bags, 'length', 0)}
                                className="ant-btn a-btn a-btn--primary"
                                deliveryNoteData={get(deliveryNote, 'delivery_note')}
                                packageTotal={get(packages, 'length')}
                                type="primary"
                                onSuccessExportDeliveryNote={onSuccessExportDeliveryNote}
                            />
                        )}

                        {canPrint && (
                            <Button
                                className="ant-btn a-btn a-btn--primary"
                                type="primary"
                                onClick={onPrint}
                            >
                                {t('delivery_note:label.print')}
                            </Button>
                        )}

                        {canUpdate && (
                            <Button
                                className="a-btn a-btn--primary _btn-update"
                                loading={updating || !!this.state.loadingPreSubmit}
                                size="large"
                                onClick={() => this.onSubmit(false)}
                            >
                                {t('btn.save')}
                            </Button>
                        )}
                    </section>
                )}
            </Content>
        );
    }
}

DetailInfoForm.defaultProps = {
    loading: false,
    loadingCustomerDetailInfo: false,
    customerDetailInfo: {
        customer: {},
        customer_finance: {},
    },
    input: {},
    packages: [],
    errors: {},
    deliveryNote: {
        customer: {}
    }
};

DetailInfoForm.propTypes = {
    loading: PropTypes.bool,
    loadingCustomerDetailInfo: PropTypes.bool,
    customerDetailInfo: PropTypes.object,
    input: PropTypes.object,
    packages: PropTypes.array,
    errors: PropTypes.object,
    deliveryNote: PropTypes.object
};

export default DetailInfoForm;
